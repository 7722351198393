import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "timChambers",
  "categories": ["testing"],
  "date": "2021-07-22",
  "path": "/blog/test-like-you-fly-2",
  "summary": "The Basics of TLYF - where to start",
  "title": "Test Like You Fly - Basics",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Test Like You Fly - Basics",
      "description": "The Basics of TLYF"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://hint.io/blog/test-like-you-fly-1"
      }}>{`In Test Like You Fly - Part 1 - Introduction`}</a>{` we discussed the characteristics of TLYF as a practice. Let's dive right into the basics.`}</p>
    <p>{`The examples will use Ruby as the language, but should be generic enough to apply to any systems in most any modern language. Unlike Ruby with its duck-typing approach, some technical issues are off the table for statically-typed or type-enforced languages. YMMV, but the concepts are the same.`}</p>
    <h2>{`Principles`}</h2>
    <p>{`All strategies are founded on principles. TLYF is no different.`}</p>
    <ul>
      <li parentName="ul">{`Ensure the developer is not the only tester/reviewer`}</li>
      <li parentName="ul">{`Measure code coverage - what is measured improves`}
        <ul parentName="li">
          <li parentName="ul">{`Skip spec/factory directories when measuring coverage - they are either 100% covered or will bias the numbers`}
            <ul parentName="li">
              <li parentName="ul">{`Artificially increases coverage`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Render views/pages in specs to maximize coverage potential`}
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Sometimes`}</em>{` developers introduce business logic in view code`}</li>
              <li parentName="ul">{`Note: unfortunately many coverage measurement tools do not count lines in views/pages`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Always match test environment versions to the production environment `}<em parentName="li">{`exactly`}</em>
        <ul parentName="li">
          <li parentName="ul">{`Never let developers code with versions you are not going to ship`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Test the hard-to-test stuff`}
        <ul parentName="li">
          <li parentName="ul">{`Documentation`}</li>
          <li parentName="ul">{`Initializers (one-time and first-time code)`}</li>
          <li parentName="ul">{`Deploy process code (the code that installs and upgrades your app in production)`}</li>
          <li parentName="ul">{`Data migrations (both upgrading and downgrading, if that is a feature)`}</li>
          <li parentName="ul">{`Error messaging (go out of the way to make sure errors in error handling do not occur)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Automate all tests - even the hard ones - so you keep yourself honest`}
        <ul parentName="li">
          <li parentName="ul">{`CI/CD should test the deploy processing`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Avoid negative testing (do not use `}<inlineCode parentName="li">{`.not_to do xyz`}</inlineCode>{`) - the possible non-results of any system is infinite`}</li>
    </ul>
    <p>{`Anything declared "cannot be tested" is a critical fault risk and should be treated as such - Tenet #4 - When you cannot test like you fly - worry (or do risk management)`}</p>
    <h2>{`Coverage - "I got you covered"`}</h2>
    <p>{`What is measured improves. What is not measured leaves doubt. We have the tools to ensure we know what we have tested, and what we have not. Still, coverage is a vague term. Is the line `}<inlineCode parentName="p">{`xyzzy(123) if a == 1`}</inlineCode>{` covered if `}<inlineCode parentName="p">{`a`}</inlineCode>{` is 2? Most coverage tools say YES it is. Let's examine some ways to improve the value of line of code coverage analysis.`}</p>
    <ul>
      <li parentName="ul">{`Can't test code you don't have, but you can ensure code you do have is executed`}</li>
      <li parentName="ul">{`Avoid complex single-line conditionals - more than 2 predicates is a caution`}</li>
      <li parentName="ul">{`Break multi-part conditionals into well-named predicate methods`}
        <ul parentName="li">
          <li parentName="ul">{`Naming methods gives you the opportunity to clearly state the one simple function the method provides`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Avoid single in-line conditionals as much as reasonable (they count as covered even if false)`}</li>
      <li parentName="ul">{`Prefer if/case/when statements over in-line conditionals`}
        <ul parentName="li">
          <li parentName="ul">{`Multi-line conditionals don't obfuscate line-of-code coverage`}</li>
          <li parentName="ul">{`They increase definition and comprehension`}</li>
          <li parentName="ul">{`You can see which lines are "covered" and which are not`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Keep methods short and single-responsibility (SRP)`}
        <ul parentName="li">
          <li parentName="ul">{`1-3 line methods are ideal - use complexity metrics as your guide`}</li>
          <li parentName="ul">{`Keep your lines of code short - easily-read code is easier to see errors`}</li>
        </ul>
      </li>
    </ul>
    <p>{`A line of code that is not covered by specs/tests will run the first time in production (see Tenet #1)`}</p>
    <h2>{`Mocking - "Let's pretend"`}</h2>
    <p>{`All developers have moved or stubbed code outside the system-under-test to ensure clear context and to speed up tests. Each time we risk walling off misbehavior at those interfaces. This is necessary. At the boundaries are where many errors creep in.`}</p>
    <ul>
      <li parentName="ul">{`Avoid mocking/stubbing whenever it is possible without negative impact`}</li>
      <li parentName="ul">{`Mock only realistic input and responses`}
        <ul parentName="li">
          <li parentName="ul">{`This includes data type matching of input and return values`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Never mock entire operation sequences or message chains`}</li>
      <li parentName="ul">{`Don't replace the code under test with mocked code responses that may be correct today and code change away from being hidden`}</li>
      <li parentName="ul">{`Never partially mock a response - in production you won't get half an answer`}</li>
    </ul>
    <h3>{`Example #1 - SQL`}</h3>
    <ul>
      <li parentName="ul">{`Specs tested the `}<strong parentName="li">{`content`}</strong>{` of SQL generated dynamically for user-expressed search options`}</li>
      <li parentName="ul">{`Confirmed generated SQL was 'as expected' and specs ran fast (no actual DB queries were made) - it "looked" correct`}</li>
      <li parentName="ul">{`Did NOT confirm SQL was executable by the DB`}</li>
      <li parentName="ul">{`Application passed specs and failed to generate valid executable SQL syntax in production`}</li>
      <li parentName="ul"><strong parentName="li">{`Moral: Pass requests to the actual destination services using the same version levels and service apps used in production (as in flight)`}</strong></li>
    </ul>
    <h2>{`Data - "The fabric of our lives"`}</h2>
    <p>{`Ok so data is not cotton. But it is fuel and as such can burn us. Treat data and especially changes in data with the reverence it deserves.`}</p>
    <ul>
      <li parentName="ul">{`Treat immutable data as immutable in tests - don’t alter data that cannot be altered in production`}</li>
      <li parentName="ul">{`Write-once data should have code to ensure it is immutable`}
        <ul parentName="li">
          <li parentName="ul">{`Setter methods can prevent/announce attempts to change immutable data signalling a potential flaw`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Ensure data represents real world data in the normal case - test edge cases to ensure they do not stretch the definition of “realistic”`}
        <ul parentName="li">
          <li parentName="ul">{`Ex: Don’t use 1 as a common value if the normal data is in the range of 10,000..10,000,000`}</li>
          <li parentName="ul">{`Test for negative values if unsigned`}</li>
          <li parentName="ul">{`Use values prime relative to each other in specs that count/sum/average`}
            <ul parentName="li">
              <li parentName="ul">{`1 and 1 are not the same as 0 and 2 but they often end up with the same results`}</li>
              <li parentName="ul">{`Values such as 2, 3, 5, 7 11, 13 allow us to experience differentiation`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Select production-like data - the data should be something any typical user would recognize`}
        <ul parentName="li">
          <li parentName="ul">{`Testing with fake data is fine, as long as the fake data simulates real-world data`}</li>
          <li parentName="ul">{`I.e. if data is for a name field consider variety of names. If the data will be numeric introduce the proper number of decimals and large values.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Ensure widest possible variety of test example data content. Per Tenet #1 do not present a certain class of data for the first time in production`}</li>
      <li parentName="ul">{`Treat meta-data as data. Ex: HTTP params are STRINGS, not objects, as they are received from a browser`}
        <ul parentName="li">
          <li parentName="ul">{`Ex: Don’t test with `}<inlineCode parentName="li">{`Date`}</inlineCode>{` objects when your data is date strings`}</li>
          <li parentName="ul">{`Don’t test with integers if the input is a numeric STRING`}</li>
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`Float`}</inlineCode>{` values WITH decimals if the numeric values can have decimals`}</li>
          <li parentName="ul">{`If `}<inlineCode parentName="li">{`BigDecimal`}</inlineCode>{` values are exchanged ensure they are tested for class`}</li>
          <li parentName="ul">{`Use the proper number of decimal places. USD$ are in dollars and cents with 2 decimal places`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Case-sensitivity is real. Your DB might or might not be, and your language may or may not have case-insensitive classes of functions/objects`}
        <ul parentName="li">
          <li parentName="ul">{`Make sure you test for case matching at any data interface`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`save!`}</inlineCode>{` or `}<inlineCode parentName="li">{`update!`}</inlineCode>{` where applicable to ensure failed validations bubble up and exceptions are announced`}</li>
      <li parentName="ul">{`If your data is an `}<inlineCode parentName="li">{`Array`}</inlineCode>{` ensure the elements are of the type you are expecting`}
        <ul parentName="li">
          <li parentName="ul">{`If you always expect an `}<inlineCode parentName="li">{`Array`}</inlineCode>{` to have content, test for that`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Use foreign keys in your database to keep any associated persisted data honest`}</li>
      <li parentName="ul">{`If using default values for columns in a database, ensure that tests prove they are not the only possible values`}</li>
      <li parentName="ul">{`Make sure critical columns are marked not null - especially simple booleans that must be true or false - not "falsey" if nil`}</li>
    </ul>
    <h3>{`Example #2 - String Input`}</h3>
    <ul>
      <li parentName="ul">{`A certain controller expects date strings for starting and ending dates (e.g. "2021-01-03")`}</li>
      <li parentName="ul">{`Used `}<inlineCode parentName="li">{`Date.parse()`}</inlineCode>{` to parse and validate`}</li>
      <li parentName="ul">{`Controller specs did not need to pass in dates because it substituted default dates when input was absent`}</li>
      <li parentName="ul">{`Default dates were `}<inlineCode parentName="li">{`Date`}</inlineCode>{` objects not `}<inlineCode parentName="li">{`String`}</inlineCode></li>
      <li parentName="ul">{`When the default dates were substituted in, the `}<inlineCode parentName="li">{`Date.parse()`}</inlineCode>{` failed because the `}<inlineCode parentName="li">{`Date`}</inlineCode>{` objects were not `}<inlineCode parentName="li">{`String`}</inlineCode>{` objects`}</li>
      <li parentName="ul"><strong parentName="li">{`Moral: Always test with expected data types. That includes when they are mocked`}</strong></li>
    </ul>
    <h3>{`Example #3 - Coordinated Values`}</h3>
    <ul>
      <li parentName="ul">{`Parent/child database tables had a denormalized common "start date" value for performance. Start date is "immutable" for all intents and purposes after row is created in both tables`}</li>
      <li parentName="ul">{`Specs use factories to generate child data which generates consistent parent data (exactly same value)`}</li>
      <li parentName="ul">{`Specs then "adjust" the child's start date to test a particular condition`}</li>
      <li parentName="ul">{`The specs pass even though the data is not consistent with real world immutability/equality between parent and child`}</li>
      <li parentName="ul"><strong parentName="li">{`Moral: Never adjust data in tests to create "impossible" combinations of real world data`}</strong></li>
      <li parentName="ul">{`Hint: Enforce data validations that ensure immutability of data once generated`}</li>
      <li parentName="ul">{`Hint #2: Ensure tests fail if invalid combinations of data are encountered`}</li>
    </ul>
    <p>{`The above is just a starting point for you as you examine your development and testing practices through the TLYF lens. Our third article in this series will cover "the world around you". Looking forward to your feedback.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      